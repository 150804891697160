import { OrderPoint, WarehouseDeliveryDetails } from "api/orders/models";
import externalImg from "assets/images/p49.png";

// awaiting - normal priority
import awaitingNormal0 from "assets/images/mapMarkers/awaiting/awaiting0.svg";
import awaitingNormal1 from "assets/images/mapMarkers/awaiting/awaiting1.svg";
import awaitingNormal2 from "assets/images/mapMarkers/awaiting/awaiting2.svg";
import awaitingNormal3 from "assets/images/mapMarkers/awaiting/awaiting3.svg";
import awaitingNormal4 from "assets/images/mapMarkers/awaiting/awaiting4.svg";
import awaitingNormal5 from "assets/images/mapMarkers/awaiting/awaiting5.svg";
import awaitingNormal6 from "assets/images/mapMarkers/awaiting/awaiting6.svg";
import awaitingNormal7 from "assets/images/mapMarkers/awaiting/awaiting7.svg";

// awaiting - high priority
import awaitingHigh0 from "assets/images/mapMarkers/awaiting/awaiting0F.svg";
import awaitingHigh1 from "assets/images/mapMarkers/awaiting/awaiting1F.svg";
import awaitingHigh2 from "assets/images/mapMarkers/awaiting/awaiting2F.svg";
import awaitingHigh3 from "assets/images/mapMarkers/awaiting/awaiting3F.svg";
import awaitingHigh4 from "assets/images/mapMarkers/awaiting/awaiting4F.svg";
import awaitingHigh5 from "assets/images/mapMarkers/awaiting/awaiting5F.svg";
import awaitingHigh6 from "assets/images/mapMarkers/awaiting/awaiting6F.svg";
import awaitingHigh7 from "assets/images/mapMarkers/awaiting/awaiting7F.svg";

// awaiting - critical priority
import awaitingCritical0 from "assets/images/mapMarkers/awaiting/awaiting0S.svg";
import awaitingCritical1 from "assets/images/mapMarkers/awaiting/awaiting1S.svg";
import awaitingCritical2 from "assets/images/mapMarkers/awaiting/awaiting2S.svg";
import awaitingCritical3 from "assets/images/mapMarkers/awaiting/awaiting3S.svg";
import awaitingCritical4 from "assets/images/mapMarkers/awaiting/awaiting4S.svg";
import awaitingCritical5 from "assets/images/mapMarkers/awaiting/awaiting5S.svg";
import awaitingCritical6 from "assets/images/mapMarkers/awaiting/awaiting6S.svg";
import awaitingCritical7 from "assets/images/mapMarkers/awaiting/awaiting7S.svg";

import avgPace from "assets/images/mapMarkers/avg_pace.png";
import visibilityLock from "assets/images/mapMarkers/visibility_lock.png";
import { mapIcons } from "assets/images/mapIcons/generatedMapIcons";
import { Assign } from "utility-types";
import { WarehouseIconKind } from "api/wms/models";
import { OrderTypeChoices, PriorityChoices } from "api/orders/enums";

const awaitingMarkerIconsMap = {
  "<1": {
    normal: awaitingNormal0,
    high: awaitingHigh0,
    critical: awaitingCritical0,
  },
  "1": {
    normal: awaitingNormal1,
    high: awaitingHigh1,
    critical: awaitingCritical1,
  },
  "2": {
    normal: awaitingNormal2,
    high: awaitingHigh2,
    critical: awaitingCritical2,
  },
  "3": {
    normal: awaitingNormal3,
    high: awaitingHigh3,
    critical: awaitingCritical3,
  },
  "4": {
    normal: awaitingNormal4,
    high: awaitingHigh4,
    critical: awaitingCritical4,
  },
  "5": {
    normal: awaitingNormal5,
    high: awaitingHigh5,
    critical: awaitingCritical5,
  },
  "6": {
    normal: awaitingNormal6,
    high: awaitingHigh6,
    critical: awaitingCritical6,
  },
  "7-<": {
    normal: awaitingNormal7,
    high: awaitingHigh7,
    critical: awaitingCritical7,
  },
};

function getAwaitingMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "warehouseDeliveryDetails"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
) {
  const priority =
    location.priority === "NORMAL"
      ? "normal"
      : location.priority === "CRITICAL"
      ? "critical"
      : "high";

  const days = location.warehouseDeliveryDetails?.daysLeftToDelivery!;

  if (days < 1) {
    return awaitingMarkerIconsMap["<1"][priority];
  }
  if (days === 1) {
    return awaitingMarkerIconsMap["1"][priority];
  }
  if (days === 2) {
    return awaitingMarkerIconsMap["2"][priority];
  }
  if (days === 3) {
    return awaitingMarkerIconsMap["3"][priority];
  }
  if (days === 4) {
    return awaitingMarkerIconsMap["4"][priority];
  }
  if (days === 5) {
    return awaitingMarkerIconsMap["5"][priority];
  }
  if (days === 6) {
    return awaitingMarkerIconsMap["6"][priority];
  }
  if (days >= 7) {
    return awaitingMarkerIconsMap["7-<"][priority];
  }
  return awaitingMarkerIconsMap["7-<"][priority];
}

function getStandardMarkerIcon(
  location: Pick<
    OrderPoint,
    "hasUpholstery" | "type" | "leftDays" | "priority" | "numberOfDaysFromCreatedDate" | "warehouse"
  >,
): string {
  const kind = location.hasUpholstery ? "upholstery" : "box";
  const type = location.type;
  const priority = location.priority;
  const days =
    location.type === OrderTypeChoices.STANDARD
      ? location.leftDays
      : (location.numberOfDaysFromCreatedDate - 14) * -1;

  const daySelector = getDaySelector(days);
  const typeSelector = getTypeSelector({ type, kind, priority });
  const warehouseSelector = getWarehouseSelector(
    location.warehouse?.icon || WarehouseIconKind.ROUND,
  );
  const key = `p${typeSelector}${daySelector}${warehouseSelector}` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}

function getWarehouseSelector(warehouseIconKind: WarehouseIconKind) {
  switch (warehouseIconKind) {
    case WarehouseIconKind.ROUND:
      return "";
    case WarehouseIconKind.SQUARE:
      return "S";
    case WarehouseIconKind.TRIANGLE:
      return "T";
    case WarehouseIconKind.DIAMOND:
      return "D";
    case WarehouseIconKind.PENTAGON:
      return "P";
    default: {
      const exhaustiveCheck: never = warehouseIconKind;
      console.error(`Unhandled warehouse icon kind: ${exhaustiveCheck}`);
      return "";
    }
  }
}

function getTypeSelector({
  kind,
  priority,
  type,
}: {
  type: OrderTypeChoices;
  kind: "box" | "upholstery";
  priority: PriorityChoices;
}) {
  if (type === OrderTypeChoices.COMPLAINT) {
    if (kind === "upholstery") {
      if (priority === PriorityChoices.CRITICAL) return "URS";
      if (priority === PriorityChoices.HIGH) return "URF";
      return "UR";
    }

    if (priority === PriorityChoices.CRITICAL) return "RS";
    if (priority === PriorityChoices.HIGH) return "RF";
    return "R";
  }

  if (kind === "upholstery") {
    if (priority === PriorityChoices.CRITICAL) return "US";
    if (priority === PriorityChoices.HIGH) return "UF";
    return "U";
  }

  if (priority === PriorityChoices.CRITICAL) return "S";
  if (priority === PriorityChoices.HIGH) return "F";

  return "B";
}

function getDaySelector(days: number) {
  if (days >= 14) {
    return 1;
  }
  if (days >= 7) {
    return 3;
  }
  if (days >= 2) {
    return 2;
  }
  if (days >= 0) {
    return 4;
  }
  if (days >= -7) {
    return 5;
  }
  if (days >= -14) {
    return 7;
  }
  return 8;
}

export function getPinnedMarkerIcon(warehouseIconKind: WarehouseIconKind): string {
  const warehouseSelector = getWarehouseSelector(warehouseIconKind);

  const key = `pinned${warehouseSelector}` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}
export function getMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "id"
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "warehouse"
      | "isHidden"
      | "hideUntilIssueIsSolved"
      | "hideOnMapTo"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
): string {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (!location.warehouseDeliveryDetails) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.isInWarehouse) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.date === null) return getStandardMarkerIcon(location);

  return getAwaitingMarkerIcon(location);
}

export function getMarkerIconForGroups(
  location: Pick<
    OrderPoint,
    | "hasUpholstery"
    | "type"
    | "leftDays"
    | "numberOfDaysFromCreatedDate"
    | "warehouse"
    | "priority"
    | "isHidden"
    | "hideUntilIssueIsSolved"
    | "hideOnMapTo"
  > & {
    method: string;
  },
  isPinned: boolean,
) {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (isPinned) {
    return externalImg;
  }
  return getStandardMarkerIcon(location);
}
